"use client"
/* eslint-disable react-hooks/exhaustive-deps */
import utils from 'styles/globals/utils.module.scss';
import Page from 'styles/pages/NotFound.module.scss';
import hero from 'public/assets/404/404_Hero_desktop.jpeg';
import heroMobile from 'public/assets/404/404_Hero_Mobile.jpeg'

import Card from "components/display/Card";
import CardTitle from "components/display/CardTitle";
import Divider from "components/display/Divider";
import RolloverImage from "components/display/RolloverImage";
import TextBlock from "components/display/TextBlock";
import Grid from 'components/layout/Grid';
import GridColumn from 'components/layout/GridColumn';
import clsx from "clsx";
import Link from "next/link";
import IconCategoryRow from "components/blocks/IconCategoryRow";
import NostoPlacement from "thirdparty/Nosto/NostoPlacement";

import * as Sentry from '@sentry/nextjs';
import Picture from 'components/display/Picture';
import NostoEventTracker from 'thirdparty/Nosto/NostoEventTracker';
import ChatButton from 'components/help/Chat/ChatButton';
import { useEffect } from 'react';


function NotFound({ statusCode, err }) {
	
    useEffect(() => {
        try{
            const event = Sentry.addEventProcessor((event) => event);
    
            if( event.exception ) {
                Sentry.showReportDialog({ 
                    eventId: event.event_id,
                    dsn: SENTRY_DSN 
                });
            }
    
        }catch(error){
            console.log(error)
        }
        
    }, [])
    

   
	return(
        <>
            <NostoEventTracker 
                trackerObject={{ 
                    event: "View404",
                }}
            />


            <header className={clsx(Page.header, utils.mb_2)}>
                <Picture 
                    desktop={hero.src}
                    width={1496} 
                    height={700}
                    mobile={heroMobile.src}
                    alt="404"
                />
                		
            </header>


            <TextBlock>
                <h1 className={utils.fs_1}>
                    We cannot find the page you're looking for.
                </h1>

                <p>
                    {statusCode && `An error ${statusCode} occurred on server`}. No need to worry. A member of our team has been notified and will resolve the issue shortly
                </p>

                <h4 className={utils.fs_4}>
                    Maybe these suggestions can help:
                </h4>

                <p>Browse our <Link href="/shop-all-brands" title="Shop all Brands">product catalog</Link> or search for something specific using the top menu bar.</p>
                
                <p>Find outdoor living trends, tips, inspiration and more on <Link href="/blog" title="Outdoor Blog">our blog</Link>.</p>
                
                <p><ChatButton /> with one of our customer service reps online or give us a call at <a href="tel:1-833-819-5994">1-833-819-5994</a>.</p>
            </TextBlock>


            <Divider className={utils.my_5} />


            <Grid>
                <GridColumn isRow start={2} end={10}>
                    <IconCategoryRow title="Explore Our Catalog" />
                </GridColumn>
            </Grid>


            <NostoPlacement 
                id="notfound-nosto-1" 
                type="slider" 
                showTopDivider 
                showBottomDivider 
                size={6}
            />


            <Grid>
                <GridColumn isRow start={2} end={10}>
                    <h3 className={clsx(utils.fs_3, utils.mb_5, utils.text_center, utils.w_100)}>
                        Shop Our Most Popular Brands
                    </h3>
                </GridColumn>

                <GridColumn isRow start={2} end={10} rowStart={2} rowEnd={3}>
                    <Grid md={4} sm={2} gap={10}>
                        <Card noPad>
                            <RolloverImage 
                                width={358}
                                height={236}
                                primarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Images/JPGS/WS00125_POVLDesktop.jpg"} 
                                secondarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Logos/PNGS/WS00040_POVLLogo_Desktop.png"} 
                                title="Shop POVL" 
                                href="/shop-all-brands/povl-outdoor" 
                                isOverlay
                            />
                            <CardTitle className={clsx(utils.fs_4, utils.mt_2)}>
                                POVL Outdoor
                            </CardTitle>
                        </Card>

                        <Card noPad>
                            <RolloverImage 
                                width={358}
                                height={236}
                                primarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Images/JPGS/WS00125_KingsleyBateDesktop.jpg"} 
                                secondarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Logos/PNGS/WS00040_KingsleyBateLogo_Desktop.png"} 
                                title="Shop Kingsley Bate" 
                                href="/shop-all-brands/kingsley-bate" 
                                isOverlay
                            />
                            <CardTitle className={clsx(utils.fs_4, utils.mt_2)}>
                                Kingsley Bate
                            </CardTitle>
                        </Card>

                        <Card noPad>
                            <RolloverImage 
                                width={358}
                                height={236}
                                primarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Images/JPGS/WS00125_GlosterDesktop.jpg"} 
                                secondarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Logos/PNGS/WS00040_GlosterLogo_Desktop.png"} 
                                title="Shop Gloster" 
                                href="/shop-all-brands/gloster" 
                                isOverlay
                            />
                            <CardTitle className={clsx(utils.fs_4, utils.mt_2)}>
                                Gloster
                            </CardTitle>
                        </Card>

                        <Card noPad>
                            <RolloverImage 
                                width={358}
                                height={236}
                                primarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Images/JPGS/WS00125_TreasureGardenDesktop.png"} 
                                secondarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Logos/PNGS/WS00040_TreasureGardenLogo_Desktop.png"} 
                                title="Shop Treasure Garden Umbrellas" 
                                href="/shop-all-brands/treasure-garden" 
                                isOverlay
                            />
                            <CardTitle className={clsx(utils.fs_4, utils.mt_2)}>
                                Treasure Garden
                            </CardTitle>
                        </Card>

                        <Card noPad>
                            <RolloverImage 
                                width={358}
                                height={236}
                                primarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Images/JPGS/WS00125_BarlowTyrieDesktop.jpg"} 
                                secondarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Logos/PNGS/WS00040_BarlowTyrieLogo_Desktop.png"} 
                                title="Shop Barlow Tyrie" 
                                href="/shop-all-brands/barlow-tyrie" 
                                isOverlay
                            />
                            <CardTitle className={clsx(utils.fs_4, utils.mt_2)}>
                                Barlow Tyrie
                            </CardTitle>
                        </Card>

                        <Card noPad>
                            <RolloverImage 
                                width={358}
                                height={236}
                                primarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Images/JPGS/WS00125_NardiDesktop.png"} 
                                secondarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Logos/PNGS/WS00040_NardiLogo_Desktop.png"} 
                                title="Shop Nardi" 
                                href="/shop-all-brands/nardi" 
                                isOverlay
                            />
                            <CardTitle className={clsx(utils.fs_4, utils.mt_2)}>
                                Nardi
                            </CardTitle>
                        </Card>

                        <Card noPad>
                            <RolloverImage 
                                width={358}
                                height={236}
                                primarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Images/JPGS/WS00125_ElementiDesktop.jpg"} 
                                secondarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Logos/PNGS/WS00040_ElementiLogo_Desktop.png"} 
                                title="Shop Elementi Fire Pits" 
                                href="/shop-all-brands/elementi" 
                                isOverlay
                            />
                            <CardTitle className={clsx(utils.fs_4, utils.mt_2)}>
                                Elementi
                            </CardTitle>
                        </Card>

                        <Card noPad>
                            <RolloverImage 
                                width={358}
                                height={236}
                                primarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Images/JPGS/WS00125_HoueDesktop.png"} 
                                secondarySrc={"https://authenteak.s3.us-east-2.amazonaws.com/WS00125_062521_404Page_Images/WS00125_062521_404Page_Logos/PNGS/WS00040_HoueLogo_Desktop.png"} 
                                title="Shop Houe" 
                                href="/shop-all-brands/houe" 
                                isOverlay
                            />
                            <CardTitle className={clsx(utils.fs_4, utils.mt_2)}>
                                Houe
                            </CardTitle>
                        </Card>
                    </Grid>
                </GridColumn>
            </Grid>
        </>
    )
}



NotFound.getInitialProps = async ({ pathname, query, asPath, req, res, err }) => {
	await Sentry.captureUnderscoreErrorException({ pathname, query, asPath, req, res, err });

	const statusCode = res ? res.statusCode : err ? err.statusCode : 404
	return { statusCode, err }
}

export default NotFound

