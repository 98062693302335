import PropTypes from 'prop-types';
import clsx from 'clsx';
//import Link from 'next/link';
import SmartLink from 'components/navigation/SmartLink';
import Button from 'components/forms/Button';
import utils from 'styles/globals/utils.module.scss';

import Style from '../Card/Card.module.scss';


/* Card CTAs Component
------------------------------------*/ 
function CardLinkArea({ children, href, title, className, color, ...newProps }){
    const bgColorClass = clsx(color && utils[`bg_${color?.class ?? color}`]);
    return (
        <>
            {href ? 
                <SmartLink href={href} className={clsx(Style.linkArea, className, bgColorClass)} title={title}>
                    {children}
                </SmartLink>
            :
                <Button {...newProps} type="link" color="transparent" autoHeight>
                    {children}
                </Button>
            }
        </>
    )
}


CardLinkArea.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    href: PropTypes.string,
    title: PropTypes.string
};


export default CardLinkArea