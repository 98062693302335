import PropTypes from 'prop-types';
import Card from "components/display/Card";
import CardLinkArea from "components/display/CardLinkArea";
import CardMedia from "components/display/CardMedia";
import CardTitle from "components/display/CardTitle";
import Grid from 'components/layout/Grid';
import GridColumn from 'components/layout/GridColumn';
import clsx from 'clsx';

import Styles from './IconCategoryRow.module.scss'
import utils from 'styles/globals/utils.module.scss';


function IconCategoryRow({ title = "Shop By Category", className, isColored, showCategoryIcons }){
    return(
        <Grid className={clsx(className, utils.my_6)}>
            <GridColumn isRow start={2} end={10} rowStart={1} rowEnd={2} className={showCategoryIcons && Styles.titleHide}>
                <h3 className={clsx(utils.fs_3, utils.mb_2, utils.text_center, utils.w_100)}>
                    {title}
                </h3>
            </GridColumn>
    
            <GridColumn isRow start={1} end={11} rowStart={2} rowEnd={3} className={isColored && Styles.oceanBg}>
                <Grid xl={8} md={4} className={Styles.block}>
                    <Card className={Styles.card}>
                        <CardLinkArea href="/outdoor-furniture/lounging" title="Outdoor Lounging Furniture" className={Styles.linkCntr}>
                            <>
                                <CardMedia src="/assets/icons/Lounging.svg" height="100" width="100" type="image" className={isColored && Styles.oceanIcon} />
                                <CardTitle className={clsx(utils.fs_5, utils.p_0, utils.m_0, isColored && Styles.oceanBg)}>
                                    <small>Lounging</small>
                                </CardTitle>
                            </>
                        </CardLinkArea>
                    </Card>

                    <Card className={Styles.card}>
                        <CardLinkArea href="/outdoor-furniture/dining" title="Outdoor Dining Furniture" className={Styles.linkCntr}>
                            <>
                                <CardMedia src="/assets/icons/Dining.svg" height="100" width="100"  type="image" className={isColored && Styles.oceanIcon} />
                                <CardTitle className={clsx(utils.fs_5, utils.p_0, utils.m_0, isColored && Styles.oceanBg)}>
                                    <small>Dining</small>
                                </CardTitle>
                            </>
                        </CardLinkArea>
                    </Card>

                    <Card className={Styles.card}>
                        <CardLinkArea href="/patio-umbrellas-accessories" title="Patio Umbrellas and Accessories" className={Styles.linkCntr}>
                            <>
                                <CardMedia src="/assets/icons/Umbrellas.svg" height="100" width="100"  type="image" className={isColored && Styles.oceanIcon} />
                                <CardTitle className={clsx(utils.fs_5, utils.p_0, utils.m_0, isColored && Styles.oceanBg)}>
                                    <small>Umbrellas</small>
                                </CardTitle>
                            </>
                        </CardLinkArea>
                    </Card>

                    <Card className={Styles.card}>
                        <CardLinkArea href="/grills-outdoor-kitchens" title="Grills and Outdoor Kitchens" className={Styles.linkCntr}>
                            <>
                                <CardMedia src="/assets/icons/Grills.svg" height="100" width="100"  type="image" className={isColored && Styles.oceanIcon} />
                                <CardTitle className={clsx(utils.fs_5, utils.p_0, utils.m_0, isColored && Styles.oceanBg)}>
                                    <small>Grills</small>
                                </CardTitle>
                            </>
                        </CardLinkArea>
                    </Card>
                    
                    <Card className={Styles.card}>
                        <CardLinkArea href="/outdoor-heating/fire-pits-fire-tables/" title="Fire Pits and Fire Tables" className={Styles.linkCntr}>
                            <>
                                <CardMedia src="/assets/icons/FirePits.svg" height="100" width="100" type="image" className={isColored && Styles.oceanIcon} />
                                <CardTitle className={clsx(utils.fs_5, utils.p_0, utils.m_0, isColored && Styles.oceanBg)}>
                                    <small>Fire Pits</small>
                                </CardTitle>
                            </>
                        </CardLinkArea>
                    </Card>

                    <Card className={Styles.card}>
                        <CardLinkArea href="/outdoor-heating/patio-heaters/" title="Outdoor Patio Heaters" className={Styles.linkCntr}>
                            <>
                                <CardMedia src="/assets/icons/PatioHeaters.svg" height="100" width="100"  type="image" className={isColored && Styles.oceanIcon} />
                                <CardTitle className={clsx(utils.fs_5, utils.p_0, utils.m_0, isColored && Styles.oceanBg)}>
                                    <small>Heaters</small>
                                </CardTitle>
                            </>
                        </CardLinkArea>
                    </Card>

                    <Card className={Styles.card}>
                        <CardLinkArea href="/outdoor-decor/" title="Decor Accessories" className={Styles.linkCntr}>
                            <>
                                <CardMedia src="/assets/icons/Decor.svg" height="100" width="100"  type="image" className={isColored && Styles.oceanIcon} />
                                <CardTitle className={clsx(utils.fs_5, utils.p_0, utils.m_0, isColored && Styles.oceanBg)}>
                                    <small>Decor</small>
                                </CardTitle>
                            </>
                        </CardLinkArea>
                    </Card>

                    <Card className={Styles.card}>
                        <CardLinkArea href="/planters/" title="Planter Accessories" className={Styles.linkCntr}>
                            <>
                                <CardMedia src="/assets/icons/Planters.svg" height="100" width="100"  type="image" className={isColored && Styles.oceanIcon} />
                                <CardTitle className={clsx(utils.fs_5, utils.p_0, utils.m_0, isColored && Styles.oceanBg)}>
                                    <small>Planters</small>
                                </CardTitle>
                            </>
                        </CardLinkArea>
                    </Card>
                    
                </Grid>
            </GridColumn>
        </Grid>
    )
}

IconCategoryRow.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    isColored: PropTypes.bool,
    showCategoryIcons: PropTypes.bool
}


export default IconCategoryRow