"use client"

/**
 * Next Image Component loader for Cloudinary
 * @param {Object} params - come from Next/Image
 * @returns cloudinary url
 */

const normalizeSrc = (src) => src[0] === '/' ? src.slice(1) : src;

function cloudinaryLoader(path, { src, width, quality }){
    return quality === 100 ? src : process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_url + `w_${width},q_${(quality || 'auto')}/f_auto/${path}/${normalizeSrc(src)}`;
}

function cloudinaryBlurLoader(path, src){
    return process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_url + `w_1,q_10/f_auto/${path}/${normalizeSrc(src)}`;
}

// Product Swatches
export const cloudinarySwatchLoader = (props) => cloudinaryLoader("swatches", { ...props })

// Product Images
export const cloudinaryProductImageLoader = (props) => cloudinaryLoader("product_images", { ...props })
export const cloudinaryBlurProductImageLoader = (src) => cloudinaryBlurLoader("product_images", src)

// CMS Directories
export const cloudinaryCms2ImageLoader = (props) => cloudinaryLoader("cms2", { ...props })
export const cloudinaryCmsImageLoader = (props) => cloudinaryLoader("cms", { ...props })

// PLP Merchandising Images
export const cloudinaryPLPImageLoader = (props) => cloudinaryLoader("plp", { ...props })