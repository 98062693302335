import isInternalLink from "lib/Utils/isInternalLink";
import Link from "next/link";

function SmartLink({ href, children, className, title, ...rest }){
	const internal = isInternalLink(href);

	return(
		<Link
			href={href}
			target={internal ? "_self" : "_blank"}
			rel={internal ? "" : "noopener noreferrer"}
			className={className}
			title={title}
			{...rest}
		>
			{children}
		</Link>
	)
};

export default SmartLink;
