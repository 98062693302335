import clsx from 'clsx';
import Grid from 'components/layout/Grid';
import GridColumn from 'components/layout/GridColumn';
import PropTypes from 'prop-types';

import Style from './TextBlock.module.scss';

function TextBlock({ fullContentBlock, children, id, className, ...otherProps }){

    return(
        <Grid className={clsx(Style.block, className)} id={id} {...otherProps} role="document">
            <GridColumn start={fullContentBlock ? 2 : 3} end={fullContentBlock ? 10 : 9}>
                {children}
            </GridColumn>
        </Grid>
    )
}


TextBlock.propTypes = {
    children: PropTypes.any,
    fullContentBlock: PropTypes.bool,
    className: PropTypes.string
}


export default TextBlock;