import Image from "next/image";
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Style from './Card.module.scss';
import Grid from 'styles/globals/gridUtils.module.scss';
import utils from 'styles/globals/utils.module.scss';

function Card({
    image,
    className,
    bodyClassName,
    noPad,
    alt,
    children,
    hasImage,
    isTextAbsent,
    ...props
}){
    let xl = props.xl === void 0 ? false : Grid[`xl_${props.xl}`],
        lg = props.lg === void 0 ? false : Grid[`lg_${props.lg}`],
        md = props.md === void 0 ? false : Grid[`md_${props.md}`],
        sm = props.sm === void 0 ? false : Grid[`sm_${props.sm}`],
        xs = props.xs === void 0 ? false : Grid[`xs_${props.xs}`];

    return(
        <div className={clsx(Style.block, isTextAbsent && Style.blockTextAbsent, className, xl, lg, md, sm, xs, noPad && Style.noPad )}>
            {image && 
                <Image 
                    src={props.image}
                    title={props.title}
                    width={imageWidth} 
                    height={266} 
                    className={Style.image}
                    alt={props.alt}
                />
            }

            <div className={clsx(Style.body, !hasImage && utils.justify_content_center, bodyClassName)}>
                {children}
            </div>
        </div>
    )
}


Card.prototype = {
    className: PropTypes.string,
    bodyClassName: PropTypes.string,
    children: PropTypes.element,
    image: PropTypes.string,
    title: PropTypes.string,
    noPad: PropTypes.block
};


export default Card;