
// Triggers Zendesk chat
/**
 * <a title="chat now" href="javascript:window.$zopim.livechat.window.show();">
 *      Your Text
 * </a>
 */

export function triggerChat(){
    if( window?.$zopim ){
        window?.$zopim?.livechat?.window.show();
    }
}
